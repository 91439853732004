.highlight-drop-target {
  position: relative;
}

.highlight-drop-target::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px dashed #2196f3;
  border-radius: 0.5rem;
  pointer-events: none;
  animation: pulse 1.5s infinite;
}

.kanban-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease, opacity 0.2s ease;
  animation: slideIn 0.3s ease;
}

.kanban-card.dragging {
  transform: scale(1.02) rotate(1deg);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  opacity: 0.9;
  cursor: grabbing !important;
}

.kanban-card.removing {
  animation: slideOut 0.3s ease forwards;
}

.kanban-card.collapsed {
  animation: collapseCard 0.3s ease forwards;
}

.kanban-card.expanded {
  animation: expandCard 0.3s ease forwards;
}

.drop-indicator {
  height: 2px;
  background-color: #2196f3;
  margin: 8px 0;
  border-radius: 1px;
  animation: scaleIn 0.2s ease;
  pointer-events: none;
}

.column-header {
  cursor: pointer;
  user-select: none;
}

.column-header:hover .column-toggle {
  opacity: 1;
}

.column-toggle {
  opacity: 0.5;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.column-content {
  transition: height 0.3s ease, opacity 0.2s ease;
  overflow: hidden;
}

.column-content.collapsed {
  height: 0 !important;
  opacity: 0;
}

.badge-counter {
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s ease;
}

.badge-counter.updating {
  animation: badgePop 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.badge-counter.increase {
  color: #4caf50;
}

.badge-counter.decrease {
  color: #f44336;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

@keyframes scaleIn {
  from { transform: scaleY(0); }
  to { transform: scaleY(1); }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes collapseCard {
  from {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  to {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }
}

@keyframes expandCard {
  from {
    opacity: 0;
    transform: translateY(10px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes badgePop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
